import React, { Component } from 'react';
import Spritesheet from 'react-responsive-spritesheet';
import { motion, AnimatePresence } from 'framer-motion';

const springAnim = {
  ease: [0.86, 0.0, 0.07, 1.0],
  duration: 1.5,
};

export default class SpriteTuto extends Component {
  render() {
    const { showTuto, isWhite } = this.props;
    const variants = {
      initial: {
        opacity: 0,
      },
      enter: {
        opacity: 1,
        transition: {
          ...springAnim,
        },
      },
      exit: {
        opacity: 0,
        transition: {
          ...springAnim,
        },
      },
    };
    const assetName = isWhite
      ? require('../../assets/transversal_assets/instructions-white_90f_500x420.png')
      : require('../../assets/transversal_assets/instructions_90f_500x420.png');
    return (
      <AnimatePresence>
        {showTuto && (
          <div className='container'>
            <motion.div
              initial='initial'
              animate='enter'
              exit='exit'
              variants={variants}
            >
              <Spritesheet
                image={assetName}
                widthFrame={500}
                heightFrame={420}
                steps={90}
                fps={30}
                loop
              />
            </motion.div>
            <style jsx>{`
              .container {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%) scale(0.5);
                margin-top: 114px;
                pointer-events: none;
              }
            `}</style>
          </div>
        )}
      </AnimatePresence>
    );
  }
}
