import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const PigmentText = ({ t, isVisible, index, color }) => {
  const wordsArray = t.split(' ');
  const words = wordsArray.map((e, i) => (
    <motion.div
      key={i}
      initial={{ opacity: 0 }}
      style={{
        display: 'inline-block',
        margin: '0 3px',
        fontFamily: 'MuseetSansBold',
        color: color,
        textShadow: color === 'white' ? '0 0 3px rgba(0,0,0,.4)' : 'inherit',
      }}
      animate={{
        opacity: 1,
        transition: {
          duration: 1,
          delay: 1 + i * 0.1,
          ease: 'easeInOut',
        },
      }}
      exit={{
        opacity: 0,
        transition: {
          duration: 1,
          delay: i * 0.03,
          ease: 'easeInOut',
        },
      }}
    >
      {e}
    </motion.div>
  ));
  return (
    <div>
      <AnimatePresence>{isVisible && words}</AnimatePresence>
      <style jsx>{`
        div {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          top: 0;
        }
      `}</style>
    </div>
  );
};

export default PigmentText;
