import * as PIXI from 'pixi.js';

export const createBrushes = () => {
  // index color pos
  const changeColorBlue = [3];
  const changeColorRed = [0, 3];
  const changeColorYellow = [0, 1];
  const changeColorGreen = [1, 3];
  const changeColorBlack = [0, 3];

  // increment speed
  const alphaSpeedBlue = 0.01;
  const alphaSpeedRed = 0.01;
  const alphaSpeedYellow = 0.01;
  const alphaSpeedGreen = 0.01;
  const alphaSpeedBlack = 0.01;

  // alpha dest
  const alphaBlue = [0.7];
  const alphaRed = [0.2, 0.4];
  const alphaYellow = [0.2, 0.4];
  const alphaGreen = [0.4, 0.4];
  const alphaBlack = [0.2];

  let brushes = [];
  function createBrush(_color, _alpha, _changeColor, _alphaSpeed) {
    for (let i = 0; i <= 4; i++) {
      const brush = {
        c: _color,
        t: PIXI.Texture.from(
          require(`../../assets/brushes/${_color}_0${i + 1}.png`)
        ),
        maxAlpha0: _alpha[0],
        maxAlpha1: _alpha[1] ? _alpha[1] : -1,
        changeColor0: _changeColor[0],
        changeColor1: _changeColor[1] ? _changeColor[1] : -1,
        alphaSpeed: _alphaSpeed,
      };
      brushes.push(brush);
    }
  }
  createBrush('blue', alphaBlue, changeColorBlue, alphaSpeedBlue);
  createBrush('red', alphaRed, changeColorRed, alphaSpeedRed);
  createBrush('yellow', alphaYellow, changeColorYellow, alphaSpeedYellow);
  createBrush('green', alphaGreen, changeColorGreen, alphaSpeedGreen);
  createBrush('black', alphaBlack, changeColorBlack, alphaSpeedBlack);

  return brushes;
};
