import React from "react";
const AudioIcon = ({color}) => {
  return (
    <svg className="icon" fill={color} height="27.25" viewBox="0 0 20.06 27.25" width="20.06" xmlns="http://www.w3.org/2000/svg">
      <path d="m7672.46 1804.95a10.013 10.013 0 0 0 -10.04 10.12c0 4.57 2.93 7.35 5.51 10.94l4.53 6.18 4.44-6.18c2.58-3.59 5.6-6.37 5.6-10.94a10.07 10.07 0 0 0 -10.04-10.12zm0 14.6a4.49 4.49 0 0 1 -4.53-4.48 4.573 4.573 0 0 1 4.53-4.57 4.5 4.5 0 0 1 4.44 4.57 4.414 4.414 0 0 1 -4.44 4.48z" fill={color} fillRule="evenodd" transform="translate(-7662.44 -1804.94)"/>
      <style jsx>
        {`
          svg {
            height: 20px;
          }
        `}
      </style>
    </svg>
  );
}

export default AudioIcon;