import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

const springAnim = {
  ease: [0.86, 0.0, 0.07, 1.0],
  duration: 1.5,
};
const Tutorial = ({ text, showTuto, isWhite, showText }) => {
  const variants = {
    initial: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
      transition: {
        ...springAnim,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        ...springAnim,
      },
    },
  };
  return (
    <AnimatePresence>
      {showTuto && (
        <motion.div
          initial='initial'
          animate='enter'
          exit='exit'
          variants={variants}
        >
          <div>
            {showText && text}
            <style jsx>{`
              div {
                position: absolute;
                bottom: 110px;
                left: 50%;
                transform: translateX(-50%);
                font-family: 'MuseetSansBold';
                font-size: 22px;
                line-height: 1.8;
                text-transform: uppercase;
                letter-spacing: 0.1em;
                color: ${isWhite ? '#fff' : '#000'};
                user-select: 'none';
              }
            `}</style>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Tutorial;
