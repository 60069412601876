import React, { Component } from 'react';
import { TweenLite } from 'gsap';
import DialRing from './DialRing';

class Dial extends Component {
  constructor(props) {
    super(props);
    this.isUnloaded = false;
    this.state = {
      percentAnimBlue: 0,
      percentAnimRed: 0,
      percentAnimYellow: 0,
      percentAnimGreen: 0,
      percentAnimBlack: 0,
      percentAnim: 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.percent !== this.props.percent) {
      this.animate(
        prevProps.percent,
        this.props.percent,
        this.props.colorStyle
      );
    }
  }

  componentWillUnmount() {
    this.isUnloaded = true;
  }

  animate(from, to, colorStyle) {
    let a = {
      percent: from > 100 ? 0 : from,
    };
    let _this = this;
    TweenLite.to(a, 2, {
      percent: to < 100 ? to : 0,
      //ease: Power2.easeOut,
      onUpdate: () => {
        if (this.isUnloaded) return;
        if (colorStyle === 'blue') {
          _this.setState({
            percentAnimBlue: a.percent,
            percentAnim: a.percent,
          });
        }
        if (colorStyle === 'red') {
          _this.setState({ percentAnimRed: a.percent, percentAnim: a.percent });
        }
        if (colorStyle === 'yellow') {
          _this.setState({
            percentAnimYellow: a.percent,
            percentAnim: a.percent,
          });
        }
        if (colorStyle === 'green') {
          _this.setState({
            percentAnimGreen: a.percent,
            percentAnim: a.percent,
          });
        }
        if (colorStyle === 'black') {
          _this.setState({
            percentAnimBlack: a.percent,
            percentAnim: a.percent,
          });
        }
      },
    });
  }

  getActiveRings = () => {
    const {
      percentAnimBlue,
      percentAnimRed,
      percentAnimYellow,
      percentAnimGreen,
      percentAnimBlack,
    } = this.state;
    const { colorStyle } = this.props;
    //console.log('blue', percentAnimBlue, 'red', percentAnimRed);
    let ringsData = [];
    if (colorStyle === 'blue') {
      ringsData.push({
        percentAnim: percentAnimBlue,
        size: 60,
        color: '#0054c2',
        colorTrack: '#fff',
      });
    }

    if (colorStyle === 'red') {
      ringsData.push({
        percentAnim: percentAnimRed,
        size: 60,
        color: '#c40022',
        colorTrack: '#fff',
      });
    }
    if (colorStyle === 'yellow') {
      ringsData.push({
        percentAnim: percentAnimYellow,
        size: 60,
        color: '#ffbe00',
        colorTrack: '#fff',
      });
    }
    if (colorStyle === 'green') {
      ringsData.push({
        percentAnim: percentAnimGreen,
        size: 60,
        color: '#00a526',
        colorTrack: '#fff',
      });
    }
    if (colorStyle === 'black') {
      ringsData.push({
        percentAnim: percentAnimBlack,
        size: 60,
        color: '#000000',
        colorTrack: '#fff',
      });
    }

    // }
    return ringsData.map((e, i) => (
      <DialRing
        key={i}
        percentAnim={e.percentAnim}
        size={e.size}
        color={e.color}
        colorTrack={e.colorTrack}
      />
    ));
  };

  render() {
    const rings = this.getActiveRings();
    const { percentAnim } = this.state;
    const showRing = percentAnim > 0 && percentAnim < 100;

    return (
      <div className='dial'>
        <div className={`container ${showRing && 'showRing'}`}>{rings}</div>
        <style jsx>{`
          .dial {
            position: absolute;
            bottom: 0px;
            right: 0;
            width: 170px;
            height: 170px;
            pointer-events: none;
            //background-image: url(${require('../../assets/backgrounds/shadow-ring.png')});
            //background-repeat: no-repeat;
            //background-size: 100% auto;
          }
          .container {
            position: absolute;
            bottom: 57px;
            right: 60px;
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            transition: opacity 0.7s ease-in-out, transform 0.7s ease-in-out;
            pointer-events: none;
            transform: scale3d(0.6, 0.6, 1);
          }
          .container.showRing {
            opacity: 1;
            transform: scale3d(1, 1, 1);
          }
        `}</style>
      </div>
    );
  }
}

export default Dial;
