import * as PIXI from "pixi.js";

export const createGeometries = () => {
  const squareWidth = 200;
  /*
  const radius = 1.0;
  let a_position = [];
  for (let dir = 0; dir < 6; dir++) {
    let angle0 = (dir / 6) * 2 * Math.PI;
    let angle1 = ((dir + 1) / 6) * 2 * Math.PI;
    a_position.push(
      [0, 0],
      [radius * Math.cos(angle0), radius * Math.sin(angle0)],
      [radius * Math.cos(angle1), radius * Math.sin(angle1)]
    );
  }
  let a_color = a_position.map((_, i) =>
    i % 3 === 0
      ? [0.7, 0.6, 0.9]
      : i % 6 < 3
      ? [0.53, 0.5, 0.1]
      : [0.43, 0.4, 0.4]
  );
  let a_color0 = a_color.map((_, i) => a_color[3 * ((i / 3) | 0)]);
  let a_color1 = a_color.map((_, i) => a_color[3 * ((i / 3) | 0) + 1]);
  let a_color2 = a_color.map((_, i) => a_color[3 * ((i / 3) | 0) + 2]);
  let a_barycentric = a_position.map((_, i) => [
    i % 3 === 0,
    i % 3 === 1,
    i % 3 === 2
  ]);*/

  return (
    new PIXI.Geometry()
      /*  .addAttribute("a_position", a_position, 2)
    .addAttribute("a_color0", a_color0, 3)
    .addAttribute("a_color1", a_color1, 3)
    .addAttribute("a_color2", a_color2, 3)
    .addAttribute("a_barycentric", a_barycentric, 3)*/
      .addAttribute(
        "aVertexPosition", // the attribute name
        [
          -squareWidth,
          -squareWidth, // x, y
          squareWidth,
          -squareWidth, // x, y
          squareWidth,
          squareWidth,
          -squareWidth,
          squareWidth
        ], // x, y
        2
      ) // the size of the attribute

      .addAttribute(
        "aUvs", // the attribute name
        [
          0,
          0, // u, v
          1,
          0, // u, v
          1,
          1,
          0,
          1
        ], // u, v
        2
      )
      .addIndex([0, 1, 2, 0, 2, 3])
  );
};
