import React from 'react';
import PigmentsTexts from '../PigmentsTexts/PigmentsTexts.js';
import PixiTexture from '../PixiTexture/PixiTexture.js';

import { easings } from 'oslo-ui';
import Dial from '../Dial/Dial.js';
const { easeInOutQuart } = easings;

const TIMEOUT = 5000;

export default class PageContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      section: -1,
      animateIn: false,
      animateOut: false,
      sceneVisible: false,
      percent: 0,
      colorStyle: '',
    };
  }

  componentDidMount() {
    this.setState({
      animateIn: true,
      animateOut: false,
      sceneVisible: true,
    });
    setTimeout(() => {
      this.setState({ startButtonVisible: true });
      this.animateIntroTexts();
    }, 1600);
  }

  componentDidUpdate = (prevProps) => {
    const { homeOut, triggerRestart } = this.props;

    if (homeOut !== prevProps.homeOut) {
      if (homeOut) {
      } else {
        this.setState({ animateIn: false, animateOut: true });
      }
    }

    if (triggerRestart !== prevProps.triggerRestart && triggerRestart) {
      this.restart();
    }
  };

  restart = () => {
    this.props.restart(true);
  };

  animateIntroTexts = () => {
    this.setSection();
  };

  setSection = () => {
    const { section } = this.state;
    this.setState({ section: section + 1 });
  };

  onPercent = (percent, colorStyle) => {
    this.setState({ percent, colorStyle });
  };

  render() {
    const {
      section,
      animateIn,
      animateOut,
      sceneVisible,
      percent,
      colorStyle,
    } = this.state;

    const {
      moduleData,
      showCartelButtons,
      hideArtworkButtons,
      hideCartelButtons,
      isFlipped,
      theme,
      artworkId,
    } = this.props;
    const { texts, tutorial, artworks, back } = moduleData;
    return (
      <div
        className={`page ${animateIn ? 'in' : ''} ${animateOut ? 'out' : ''}`}
      >
        {sceneVisible && (
          <>
            <PixiTexture
              section={section}
              onSection={this.setSection}
              timeoutText={TIMEOUT}
              onRestart={this.restart}
              onPercent={this.onPercent}
              tutorial={tutorial}
              theme={theme}
              showCartelButtons={showCartelButtons}
              hideArtworkButtons={hideArtworkButtons}
              artworks={artworks}
              hideCartelButtons={hideCartelButtons}
              back={back}
              artworkId={artworkId}
              isFlipped={isFlipped}
            />

            <PigmentsTexts section={section} texts={texts} percent={percent} />
            <Dial section={section} percent={percent} colorStyle={colorStyle} />
          </>
        )}

        <style jsx>{`
          .page {
            transform: translate3d(0, 100%, 0);
            transition: transform 1.5s ${easeInOutQuart};
            z-index: -1;
          }

          .page.in {
            transform: translate3d(0, 0, 0);
          }

          .page.out {
            transform: translate3d(0, 100%, 0);
          }
        `}</style>
      </div>
    );
  }
}
