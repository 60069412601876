/* eslint-disable */

import React from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { UIButton, easings } from 'oslo-ui';

export default class ArtworkImage extends React.Component {
  constructor(props) {
    super(props);

    this.zoomed = false;
    this.hasRendered = false;
    this.timeout = null;
    const { artworkId } = props;

    this.state = {
      zoomed: false,
      dimensions: null,
      ratio: null,
      zoomScale: artworkId === 0 ? 1.8 : artworkId === 1 ? 2.3 : 2.1,
      zoomInVisible: false,
      zoomOutVisible: false,
      zoomInButton: false,
      zoomOutButton: false,
      showing: false,
      canZoom: false,
    };
  }

  componentDidMount = () => {
    this.getImgSize();
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.dimensions !== nextState.dimensions ||
      this.state.zoomScale !== nextState.zoomScale ||
      this.state.zoomInVisible !== nextState.zoomInVisible ||
      this.state.zoomOutVisible !== nextState.zoomOutVisible ||
      this.state.zoomOutButton !== nextState.zoomOutButton ||
      this.state.zoomInButton !== nextState.zoomInButton ||
      this.props.hideArtworkButtons !== nextProps.hideArtworkButtons ||
      this.props.artwork !== nextProps.artwork ||
      this.props.hide !== nextProps.hide ||
      this.state.showing !== nextState.showing
    ) {
      return true;
    } else {
      return false;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.dimensions !== this.state.dimensions &&
      this.state.dimensions !== null
    ) {
      let width, height;
      document.querySelector(
        '.TransformComponent-module_container__3NwNd'
      ).style.overflow = 'visible';
      document.querySelector(
        '.TransformComponent-module_container__3NwNd'
      ).style.width = '100%';
      document.querySelector(
        '.TransformComponent-module_container__3NwNd'
      ).style.height = '100%';
      [width, height] = this.state.dimensions;
    }

    if (
      prevProps.hideArtworkButtons !== this.props.hideArtworkButtons &&
      this.props.hideArtworkButtons === true
    ) {
      setTimeout(() => {
        this.setState({ zoomOutVisible: false });
      }, 0);
      setTimeout(() => {
        this.setState({ zoomInVisible: false });
      }, 100);
    }

    if (prevProps.artwork.file !== this.props.artwork.file) {
      this.setState({ dimensions: null });
      setTimeout(() => {
        this.getImgSize();
      }, 200);
    }
    if (prevProps.hide !== this.props.hide && this.props.hide === true) {
      setTimeout(() => {
        this.setState({
          showing: true,
        });
      }, 850);
    }
  }

  getImgSize = () => {
    const {
      slug,
      artwork: { file },
    } = this.props;
    const img = new Image();

    img.onload = (image) => {
      let height = image.path[0].height;
      let width = image.path[0].width;
      this.originalHeight = image.path[0].height;
      this.originalWidth = image.path[0].width;

      let ratio = width / height;

      if (ratio > 1) {
        if (width > 1000) {
          width = 1000;
          height = width / ratio;
          while (height > 700) {
            width -= 10;
            height = width / ratio;
          }
        }
      }
      if (ratio < 1) {
        if (height > 700) {
          height = 700;
          width = ratio * height;

          while (width >= 1000) {
            height -= 10;
            width = height * ratio;
          }
        }
      }

      if (width === height) {
        if (width > 700) {
          width = 700;
          height = 700;
        }
      }

      if (ratio <= 16 / 9) {
        this.maxScale = (this.originalWidth * 1.5) / width;
      } else {
        this.maxScale = (this.originalHeight * 1.5) / height;
      }

      if (
        (ratio > 1 && this.originalWidth >= 1000) ||
        (ratio < 1 && this.originalHeight >= 700) ||
        (ratio === 1 && this.originalWidth >= 700)
      ) {
        this.props.showButtons();
        this.setState({ canZoom: true });
      } else {
        this.props.hideButtons();
        this.setState({ canZoom: false });
      }

      this.setState({ dimensions: [width, height], ratio });
      this.setState({ showing: false });
    };
    img.src = require(`../../assets/${slug}/artworks/${file}`);
  };

  checkZoomLevel = (zoom) => {
    const { dimensions } = this.state;
    if (zoom.scale > 1 && this.state.zoomed === false) {
      this.setState({ zoomed: true, zoomScale: zoom.scale });
    } else if (zoom.scale <= 1) {
      this.setState({ zoomed: false, zoomScale: zoom.scale });
      this.props.toggleCartel(true);
      document.querySelector('.reset-button').click();

      setTimeout(() => {
        document.querySelector('.react-transform-element').style.transition =
          '';
      }, 350);
    }
  };

  check = (zoom) => {
    if (zoom.scale > 1) {
      this.props.toggleCartel(false);
    }
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.checkZoomLevel(zoom);
    }, 100);
  };

  zoomIn = () => {
    document.querySelector('.zoomIn-button').click();
  };

  zoomOut = () => {
    document.querySelector('.zoomOut-button').click();
  };

  render() {
    const {
      dimensions,
      ratio,
      zoomScale,
      zoomInVisible,
      zoomOutVisible,
      zoomInButton,
      zoomOutButton,
      showing,
      canZoom,
    } = this.state;

    const {
      theme,
      artwork: { file },
      slug,
      hide,
      artworkId,
    } = this.props;
    return (
      <div
        className={`container ${hide ? 'hidden' : ''} ${
          showing ? 'showing' : ''
        }`}
      >
        {dimensions !== null && (
          <TransformWrapper
            defaultScale={artworkId === 0 ? 1.8 : artworkId === 1 ? 2.4 : 2.1}
            pan={{
              lockAxisX: zoomScale <= 1 ? true : false,
              lockAxisY: zoomScale <= 1 ? true : false,
            }}
            onZoomChange={this.check}
            onPinchingStop={this.check}
            options={{
              minScale: 1,
              maxScale: this.maxScale,
              limitToBounds: true,
              limitToWrapper: true,
            }}
            pinch={{
              disabled: !canZoom,
            }}
            defaultPositionX={
              artworkId === 0 ? 0 : artworkId === 1 ? -250 : -250
            }
            defaultPositionY={artworkId === 0 ? -50 : artworkId === 1 ? 0 : 0}
          >
            {({ zoomIn, zoomOut, resetTransform, setTransform, ...rest }) => (
              <React.Fragment>
                <button
                  className='insta-reset-button'
                  style={{ display: 'none' }}
                  onClick={() => {
                    setTransform(
                      800 - dimensions[0] / 2,
                      450 - dimensions[1] / 2,
                      0.1
                    );
                  }}
                ></button>
                <button
                  className='reset-button'
                  style={{ display: 'none' }}
                  onClick={() => {
                    setTransform(
                      800 - dimensions[0] / 2,
                      450 - dimensions[1] / 2,
                      1
                    );
                  }}
                ></button>
                <button
                  className='zoomIn-button'
                  style={{ display: 'none' }}
                  onClick={zoomIn}
                ></button>
                <button
                  className='zoomOut-button'
                  style={{ display: 'none' }}
                  onClick={zoomOut}
                ></button>
                {/*<CabinetZoom onZoomIn={zoomIn} onZoomOut={zoomOut} />*/}
                <TransformComponent>
                  <img
                    className='img'
                    style={{
                      width: `${dimensions[0]}px`,
                      height: `${dimensions[1]}px`,
                    }}
                    src={require(`../../assets/${slug}/artworks/${file}`)}
                    alt=''
                  />
                </TransformComponent>
              </React.Fragment>
            )}
          </TransformWrapper>
        )}
        <style jsx>
          {`
            .container {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              z-index: 0;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: 0;
            }

            .img {
              transition: opacity 3s ${easings.easeInOutQuart};
              transition-delay: 0s;
              opacity: 1;
            }

            .container.hidden .img {
              opacity: 0;
            }

            .container.showing .img {
            }

            .landscape::after,
            .portrait::after {
              content: '';
              display: block;
              width: 100%;
            }

            .portrait {
              height: calc(1080px - 500px);
              width: calc((1080px - 500px) / ${ratio});
            }

            .TransformComponent-module_container__3NwNd {
              width: 100%;
              height: 100%;
            }
          `}
        </style>
      </div>
    );
  }
}
