import React, { useState, useEffect } from 'react';
import LocationIcon from '../Icons/LocationIcon';
import { easings } from 'oslo-ui';

const ArtworkInformations = ({
  artwork,
  color,
  hidden,
  artworks,
  activeIndex,
  updateActiveIndex,
  transition,
}) => {
  const [hiddenContainer, setHiddenState] = useState(hidden);
  const [data, setData] = useState(artwork);
  const [transitionGoing, setTransition] = useState(false);
  const [showing, setShowing] = useState(true);

  useEffect(() => {
    setHiddenState(hidden);
  }, [hidden]);

  useEffect(() => {
    setTimeout(() => {
      setTransition(transition);
    }, 250);
  }, [transition]);

  useEffect(() => {
    setTimeout(() => {
      setData(artwork);
    }, 250);
  }, [artwork]);

  useEffect(() => {
    if (transition === true) {
      setTimeout(() => {
        setShowing(true);
        setTimeout(() => {
          setShowing(false);
        }, 300);
      }, 1100);
    }
  }, [transition]);

  return (
    <div
      className={`container ${hiddenContainer ? 'hidden' : ''} ${
        transitionGoing ? 'transitioning' : ''
      } ${showing ? 'showing' : ''}`}
    >
      <div className='top' />
      <div className='chevron' onClick={() => setHiddenState(!hiddenContainer)}>
        <div className='chevron-inner'>
          <svg width='16px' height='28px'>
            <path
              fillRule='evenodd'
              fill='rgb(0, 0, 0)'
              d='M16.000,14.000 L14.000,16.000 L13.979,15.978 L2.258,27.449 L0.551,25.742 L12.043,14.000 L0.551,2.257 L2.258,0.549 L13.979,12.022 L14.000,12.000 L16.000,14.000 Z'
            />
          </svg>
        </div>
      </div>
      <span className='author'>{data.author}</span>
      <div className='content'>
        {data.title && (
          <span
            className={`title title-${
              data.title_no_italic === true ? 'normal' : 'italic'
            }`}
          >
            {data.title}
          </span>
        )}

        {(data.year !== undefined || data.material !== undefined) && (
          <span className='informations'>
            <span className='year'>{data.year !== undefined && data.year}</span>
            {/*{(data.material !== undefined && data.year !== undefined) && 
            <span class="separator"> | </span>
          }*/}
            {data.material !== undefined && (
              <span className='material'>
                {data.material.replace(' ', '\xa0')}
              </span>
            )}
          </span>
        )}
        {data.location !== undefined && (
          <span className='location'>
            <LocationIcon color={color} />
            <span>{data.location}</span>
          </span>
        )}
        {data.description !== undefined && (
          <div className='description-container'>
            <span>{data.description}</span>
          </div>
        )}
      </div>
      <style jsx>
        {`
          .container {
            align-items: flex-start;
            background-color: #fffaf1;
            box-sizing: border-box;
            color: #272727;
            display: flex;
            right: 0;
            flex-direction: column;
            padding-right: 108px;
            justify-content: center;
            padding-bottom: 115px;
            padding-left: 68px;
            padding-right: 68px;
            padding-top: 115px;
            position: absolute;
            clip-path: polygon(100% -115px, 0 -115px, 0 100%, 100% 100%);
            transition: transform 0.55s ${easings.easeInOutQuart},
              clip-path 0.85s ${easings.easeInOutQuart};
            width: 433px;
            z-index: 1;
          }

          .container.transitioning {
            clip-path: polygon(100% -115px, 100% -115px, 100% 100%, 100% 100%);
          }

          .container.showing {
            transition: none;
            clip-path: polygon(0 -115px, 0 -115px, 0 100%, 0% 100%);
          }

          .top {
            background-color: #3c5068;
            height: 10px;
            left: 0;
            position: absolute;
            top: 0;
            transform: translate3d(0, -100%, 0);
            width: 100%;
          }

          .year {
            margin-top: 20px;
            font-size: 24px;
            display: block;
            font-family: 'MuseetSans';
          }

          .chevron {
            background-color: rgba(0, 0, 0, 0.07);
            position: absolute;
            left: 0;
            top: 0;
            height: 84px;
            width: 68px;
          }

          .chevron-inner {
            position: absolute;
            top: 50%;
            left: 50%;
            transition: transform 0.55s ${easings.easeInOutQuart};
            transform: translate3d(-50%, -50%, 0) rotate(0);
          }

          .content {
            margin-top: 20px;
          }

          .hidden {
            transform: translate3d(calc(100% - 68px), 0, 0);
          }

          .hidden .chevron-inner {
            transform: translate3d(-50%, -50%, 0) rotate(-180deg);
          }

          .author {
            color: #3c5068;
            font-family: 'MuseetSerifBold';
            line-height: 1.2;
            text-align: left;
            letter-spacing: 0.02em;
            //max-width: 90%;
            font-size: 33px;
          }

          .title {
            font-size: 26px;
            letter-spacing: 0.02em;
            line-height: 1.35;
          }

          .title-italic {
            font-family: 'MuseetSansRegularItalic';
          }

          .title-normal {
            font-family: 'MuseetSansRegular';
          }

          .informations {
            display: block;
            font-size: 32px;
            line-height: 1;
          }

          .location {
            display: flex;
            align-items: center;
            font-family: 'MuseetSans';
            font-size: 32px;
            letter-spacing: 0.2em;
            line-height: 1.4;
            text-transform: uppercase;
          }

          .location span {
            margin-left: 10px;
          }

          .material {
            display: block;
            margin-top: 12px;
            white-space: nowrap;
          }
        `}
      </style>
      <style global='true'>{`
        .artwork-cartel .description-container {
          display: block;
          font-family: "MuseetSans";
          font-size: 24px;
          line-height: 1.35;
          margin-top: 20px;
          margin-bottom: 12px;
        }

        .artwork-cartel .description-container i {
          font-family: "MuseetSansItalic";
        }

        .artwork-cartel .description-container q {
          font-family: "MuseetSerif";
        }

        .artwork-cartel .description-container q i {
          font-family: "MuseetSerifItalic";
        }

        .artwork-cartel .description-container q::before,
        .artwork-cartel .description-container q::after {
          content: '';
          display: none;
        }
      `}</style>
    </div>
  );
};

export default ArtworkInformations;
