import React, { Component } from 'react';
import * as PIXI from 'pixi.js';
import _ from 'lodash';
import { createGeometries } from './Geometries';
import { createBrushes } from './Brushes';
import { getMousePos, getRandomFloat, getRandomInt } from './MyFunctions';
import Splat from './Splat';
import VideoTransition from '../VideoTransition/VideoTransition';
import { UIButton } from 'oslo-ui';
import SpriteTuto from './SpriteTuto';
import UIAnimation from '../UIAnimation/UIAnimation';
import BlockScreen from '../BlockScreen/BlockScreen';
import Artwork from '../Artwork/Artwork';
import Tutorial from '../Tutorial/Tutorial';

const CAPTURE_DELAY = 2000;

export default class PixiTexture extends Component {
  constructor(props) {
    super(props);
    this._throttledMouseMove = _.throttle(
      this._throttledMouseMove.bind(this),
      200
    );

    this.allowTouch = false;
    this.isEnded = false;
    this.isFlipped = props.isFlipped;

    this.state = {
      showEndMask: false,
      showTuto: false,
      showBlockScreen: false,
      showArtwork: false,
    };
  }

  componentWillUnmount() {
    clearInterval(this.captureInterval);
    this.killSplats();
    this.app.destroy(true);
    this.removeEvents();
    this.isEnded = true;
  }

  closeHelpIcon = () => {
    this.setState({ showTuto: false });
  };

  componentDidMount() {
    //this.props.hideCartelButtons();

    //const { innerWidth, innerHeight } = window;
    this.innerWidth = 1600;
    this.innerHeight = 900;
    //this.innerHeight = this.innerWidth / (16 / 9);

    this.splats = [];
    this.containers = [];
    this.mouseIsDown = false;

    this.app = new PIXI.Application({
      backgroundColor: '0xffffff',
      width: this.innerWidth,
      height: this.innerHeight,
      resolution: 1,
    });
    this.container.appendChild(this.app.view);

    this.gl = this.app.renderer.gl;

    this.gl.blendFunc(this.gl.ALPHA, this.gl.ONE_MINUS_SRC_ALPHA);
    this.gl.enable(this.gl.BLEND);

    this.geometry = createGeometries();
    this.brushes = createBrushes();

    // 2 containers, backgrounds and splats
    this.initContainers();

    // 1st Background
    this.createBack('01');

    // 1st color
    this.changeColor([0, 0, 1, 0], 'blue');

    // Check blue color
    this.checkColors(2, 190, 0.8);

    this.waitAllowTouch();

    this.initEvents();
  }

  initEvents() {
    document.addEventListener('mousedown', this.onMouseDown);
    document.addEventListener('mouseup', this.onMouseUp);
    document.addEventListener('mousemove', this._onMouseMove);
    document.addEventListener('touchstart', this.onMouseDown);
    document.addEventListener('touchend', this.onMouseUp);
    document.addEventListener('touchmove', this._onMouseMove);
    /* onMouseDown={this.onMouseDown}
    onMouseUp={this.onMouseUp}
    onMouseMove={this._onMouseMove}
    onTouchStart={this.onMouseDown}
    onTouchEnd={this.onMouseUp}
    onTouchMove={this._onMouseMove}*/
  }

  removeEvents() {
    document.removeEventListener('mousedown', this.onMouseDown);
    document.removeEventListener('mouseup', this.onMouseUp);
    document.removeEventListener('mousemove', this._onMouseMove);
    document.removeEventListener('touchstart', this.onMouseDown);
    document.removeEventListener('touchend', this.onMouseUp);
    document.removeEventListener('touchmove', this._onMouseMove);
  }

  waitAllowTouch() {
    setTimeout(() => {
      this.allowTouch = true;
      this.setState({ showTuto: true });
    }, 3000);
  }

  blockScreen() {
    this.allowTouch = false;
    this.mouseIsDown = false;
    this.setState({ showBlockScreen: true });
    //console.log('blockScreen');
    setTimeout(() => {
      // console.log('remove blockScreen');
      this.allowTouch = true;
      this.setState({ showTuto: true, showBlockScreen: false });
    }, 3000);
  }

  initContainers() {
    const splatContainer = new PIXI.Container();
    const bgContainer = new PIXI.Container();
    this.app.stage.addChild(bgContainer);
    this.app.stage.addChild(splatContainer);
    this.containers.push(bgContainer, splatContainer);
  }

  changeColor(mainColor, colorStyle) {
    // Couleur rgb pour fragments shader
    this.mainColor = mainColor;

    // Nom de la couleur pour les textures
    this.colorStyle = colorStyle;

    // Remplit aléatoirement l'écran
    //setTimeout(() => {
    //this.fillSplats();
    //this.createBack(colorStyle);
    // }, 2000);
  }

  createSnap() {
    const texture = this.app.renderer.generateTexture(
      this.app.stage,
      undefined,
      undefined,
      this.app.renderer.screen
    );

    const imageExtract = this.app.renderer.plugins.extract.canvas(
      texture,
      'image/png'
    );
    const bg = PIXI.Sprite.from(imageExtract);
    bg.x = 0;
    bg.y = 0;
    bg.width = this.innerWidth;
    bg.height = this.innerHeight;
    texture.destroy(true);
    this.containers[0].addChild(bg);

    this.killSplats();

    // Remove previous bg
    this.containers[0].removeChild(this.bg);
    this.bg.destroy(true);
    this.bg = bg;
  }

  killSplats = () => {
    this.splats.map((e) => {
      this.containers[1].removeChild(e);
      e.destroy(true);
      return e;
    });
    this.splats = [];
  };

  goToEnd = () => {
    //this.fillSplats();
    this.isEnded = true;

    let _this = this;

    //black mask
    setTimeout(() => {
      _this.props.onSection();
      setTimeout(_this.props.onSection, 5500);

      _this.createSnap();

      //this.bg.alpha = 0;

      const bg = PIXI.Sprite.from(
        require('../../assets/artwork_background.jpg')
      );
      bg.width = _this.innerWidth;
      bg.height = _this.innerHeight;
      _this.bg = bg;
      _this.containers[1].addChild(bg);
      bg.alpha = 0;
      setTimeout(() => {
        bg.alpha = 1;
      }, 1000);

      const blackCaptureImg = _this.app.renderer.plugins.extract.base64(
        _this.containers[0]
      );

      _this.setState({ blackCaptureImg });

      _this.props.hideCartelButtons();

      setTimeout(() => {
        _this.setState({ showArtwork: true });
      }, 4000);

      setTimeout(() => {
        _this.setState({ isEnded: true });
        _this.killSplats();
      }, 7000);
    }, 2500);
  };

  goToColor = () => {
    if (this.colorStyle === 'blue') {
      this.fillSplats();
    }

    const { timeoutText } = this.props;
    this.props.onSection();

    this.blockScreen();

    setTimeout(() => {
      this.createSnap();
      let newColor = '';
      let newColorPos;

      //setTimeout(this.props.onSection, timeoutText);

      switch (this.colorStyle) {
        case 'blue':
          newColor = 'red';
          newColorPos = 0;

          setTimeout(this.props.onSection, timeoutText);
          setTimeout(this.props.onSection, timeoutText * 2);
          this.changeColor([1, 0, 0, 0], newColor);
          this.checkColors(newColorPos, 10, 1);
          break;

        case 'red':
          newColor = 'yellow';
          newColorPos = 1;

          setTimeout(this.props.onSection, timeoutText);
          setTimeout(this.props.onSection, timeoutText * 2);
          this.changeColor([0.3, 0.3, 0, 0], newColor);
          this.checkColors(newColorPos, 130, 0.8);
          break;

        case 'yellow':
          newColor = 'black';
          newColorPos = 0;
          setTimeout(this.props.onSection, timeoutText);
          this.changeColor([0.0, 0.0, 0, 0], newColor, 0.5);
          this.checkColors(3, 50, 0.5, true);
          break;

        default:
          newColor = '';
      }
    }, 2500);
  };

  createBack(img) {
    const bg = PIXI.Sprite.from(require(`../../assets/backgrounds/${img}.jpg`));
    bg.width = this.innerWidth;
    bg.height = this.innerHeight;
    this.bg = bg;
    this.containers[0].addChild(bg);
  }

  fillSplats() {
    let num = 0;
    switch (this.colorStyle) {
      case 'blue':
        num = 90;
        break;

      case 'red':
        num = 70;
        break;

      case 'yellow':
        num = 40;
        break;
      case 'green':
        num = 20;
        break;
      case 'black':
        num = 60;
        break;
      default:
    }

    for (let i = 0; i < num; i++) {
      const p = {
        clientX: getRandomFloat(0, this.innerWidth),
        clientY: getRandomFloat(0, this.innerHeight),
      };
      setTimeout(() => {
        this.createSplat(p, true);
      }, getRandomFloat(0, 2500));
    }
  }

  createSplat = (e, auto) => {
    const mousePos = getMousePos(e, auto, this.isFlipped);
    const colorBrushes = this.brushes.filter((e) => e.c === this.colorStyle);
    const brushId = getRandomInt(0, colorBrushes.length - 1);
    const brush = colorBrushes[brushId];

    const splat = Splat(
      this.mainColor,
      brush,
      this.geometry,
      mousePos,
      this.colorStyle
    );
    this.containers[1].addChild(splat);
    this.splats.push(splat);
  };

  checkColors(colorPos, colorMax, maxPercent, finalStep) {
    this.captureInterval = setInterval(() => {
      const pixels = this.app.renderer.plugins.extract.pixels(
        this.containers[1]
      );

      let filledInPixels = 0;

      const pixelsData = pixels;

      for (let i = 0; i < pixelsData.length; i += 4) {
        const pPos = pixelsData[i + colorPos];

        if (pPos && pPos >= colorMax) {
          filledInPixels++;
        }
      }

      const percentColor =
        filledInPixels / (this.innerWidth * this.innerHeight);

      const percentTotal = (percentColor * 100) / maxPercent;
      this.props.onPercent(percentTotal, this.colorStyle);

      if (percentColor > maxPercent) {
        clearInterval(this.captureInterval);

        if (finalStep) {
          this.goToEnd();
        } else {
          this.goToColor();
        }
      }
    }, CAPTURE_DELAY);
  }

  onMouseDown = (e) => {
    if (!this.allowTouch) return;
    if (this.state.showTuto) {
      this.setState({ showTuto: false });
    }

    this.mouseIsDown = true;
    this.onMouseMove(e);
  };

  onMouseUp = () => {
    if (!this.allowTouch) return;
    this.mouseIsDown = false;
  };

  onMouseMove = (e) => {
    if (!this.allowTouch) return;
    if (!this.isEnded) {
      this.createSplat(e, false);
    }
  };

  _throttledMouseMove = (e) => {
    if (!this.allowTouch) return;
    if (this.mouseIsDown) {
      this.onMouseMove(e);
    }
  };

  _onMouseMove = (e) => {
    if (!this.allowTouch) return;
    //e.persist();
    this._throttledMouseMove(e);
  };

  restart = () => {
    this.props.onRestart();
  };
  render() {
    const { blackCaptureImg, isEnded, showTuto, showBlockScreen, showArtwork } =
      this.state;
    const {
      tutorial,
      theme,
      showCartelButtons,
      hideArtworkButtons,
      artworks,
      back,
      section,
      artworkId,
    } = this.props;

    const isWhiteTuto = section > 0 ? true : false;
    const common = {
      buttons: {
        reset: {
          text: back,
        },
      },
    };

    const BackButton = (
      <UIButton
        type='reset'
        customClasses='align-right'
        color={'#000000'}
        background={'#ffffff'}
        common={common}
        click={this.restart}
        alignRight
      />
    );

    return (
      <React.Fragment>
        <div
          className='canvas'
          id='canvas'
          ref={(e) => (this.container = e)}
        ></div>

        <BlockScreen showBlockScreen={showBlockScreen} />
        <SpriteTuto showTuto={showTuto} isWhite={isWhiteTuto} />
        <Tutorial
          text={tutorial}
          showTuto={showTuto}
          isWhite={isWhiteTuto}
          showText={section === 0}
        />

        {blackCaptureImg && (
          <VideoTransition
            cacheIn={require('../../assets/cache_pigment_noir.mp4')}
            background={blackCaptureImg}
          />
        )}

        {showArtwork && (
          <Artwork
            artworks={artworks}
            showCartelButtons={showCartelButtons}
            hideArtworkButtons={hideArtworkButtons}
            theme={theme}
            slug={'artworks'}
            transitioning={false}
            artworkId={artworkId}
          />
        )}

        <UIAnimation button={BackButton} isEnded={isEnded}></UIAnimation>
        <style jsx>{`
          .canvas {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
          }
        `}</style>
      </React.Fragment>
    );
  }
}
