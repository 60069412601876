import React, { Component } from 'react';
import ProgressLabel from 'react-progress-label';

export default class DialRing extends Component {
  render() {
    const { percentAnim, color, colorTrack } = this.props;
    return (
      <div className='container'>
        <span className='circle'></span>
        <span className='border'></span>
        <ProgressLabel
          progress={percentAnim}
          startDegree={0}
          progressWidth={5}
          trackWidth={5}
          trackBorderWidth={0}
          trackBorderColor={color}
          cornersWidth={0}
          size={80}
          trackColor={'none'}
          fillColor='none'
          progressColor={colorTrack}
        />

        <style jsx>{`
          .container {
            position: absolute;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .circle {
            position: absolute;

            width: 35px;
            height: 35px;
            border: 5px solid #fff;
            border-radius: 100%;
            background: ${color};

            animation: pulse 2s infinite;
          }
          .border {
            position: absolute;
            top: 0;
            left: 0;
            width: 70px;
            height: 70px;
            border-radius: 100%;
            border: 5px solid rgba(0, 0, 0, 0.35);
            z-index: -1;
          }

          @keyframes pulse {
            0% {
              transform: scale3d(1, 1, 1);
            }
            50% {
              transform: scale3d(0.8, 0.8, 0.8);
            }
            100% {
              transform: scale3d(1, 1, 1);
            }
          }
        `}</style>
      </div>
    );
  }
}
