import * as PIXI from 'pixi.js';
import { getRandomFloat } from './MyFunctions';
//import { vertexSrc, fragmentSrc } from "./Shader";
import { vertexSrc, fragmentSrc } from './Shader';
import { TweenLite } from 'gsap';

const Splat = (mainColor, brush, geometry, mousePos, colorStyle, name) => {
  const { t, maxAlpha0, maxAlpha1, changeColor0, changeColor1, alphaSpeed } =
    brush;

  const u_amplitude = 0.33;
  const u_wavelength = 0.25;
  const u_bias = 0.5;
  const u_seed = 0.0;

  const randomAlpha =
    colorStyle === 'black'
      ? getRandomFloat(0.2, 0.35)
      : colorStyle === 'blue'
      ? 1
      : colorStyle === 'yellow'
      ? getRandomFloat(0.1, 0.3)
      : getRandomFloat(0.25, 0.6);

  const uniforms = {
    aColor: mainColor,
    uSampler2: t,
    maxAlpha0: maxAlpha0,
    maxAlpha1: maxAlpha1,
    changeColor0: changeColor0,
    changeColor1: changeColor1,
    alphaSpeed: alphaSpeed,
    uTime: 0,
    progress: 1,
    alphaProgress: 1,
    scaleXY: getRandomFloat(0.96, 0.99),
    rotation: 0,
    u_amplitude: u_amplitude,
    u_wavelength: u_wavelength,
    u_bias: u_bias,
    u_seed: u_seed,
    randomAlpha: randomAlpha,
  };
  //randomAlpha: getRandomFloat(0.2, 0.4)
  //console.log('uniforms',uniforms)
  const shader = PIXI.Shader.from(vertexSrc, fragmentSrc, uniforms);

  const splat = new PIXI.Mesh(geometry, shader);
  const scale =
    colorStyle === 'blue'
      ? getRandomFloat(0.7, 1.0)
      : colorStyle === 'red'
      ? getRandomFloat(0.7, 1.0)
      : getRandomFloat(0.55, 1.0);

  const alphaProgress = colorStyle === 'blue' ? 1 : randomAlpha;

  const rotation = getRandomFloat(0, 360);
  //splat.pivot.set(splat.width / 2, splat.height / 2);
  splat.position.set(mousePos.x, mousePos.y);
  splat.rotation = rotation;
  splat.scale.set(scale);
  //splat.interactive = true;
  /*splat
    //.on('pointerdown', onButtonDown)
    //.on('pointerup', onButtonUp)
    //.on('pointerupoutside', onButtonUp)
    .on("pointerover", () => {
      console.log("over");
    });*/
  //.on('pointerout', onButtonOut);
  //splat.alpha = 0;

  let blurFilter = new PIXI.filters.BlurFilter();
  blurFilter.blur = 1.1;
  blurFilter.quality = 1;
  blurFilter.resolution = 1;
  splat.filters = [blurFilter];

  TweenLite.to(uniforms, getRandomFloat(1, 1.6), {
    progress: 0,
    scaleXY: 1,
  });
  TweenLite.to(uniforms, getRandomFloat(1, 4), {
    alphaProgress: alphaProgress,
  });
  TweenLite.to(blurFilter, 2, {
    blur: 0,
    onComplete: () => {
      splat.filters = null;
    },
  });

  setTimeout(() => {
    splat.cacheAsBitmap = true;
  }, 5000);

  //splat.blendMode = PIXI.BLEND_MODES.NORMAL_NPM;
  if (colorStyle === 'red') {
    //splat.blendMode = PIXI.BLEND_MODES.SCREEN;
  }
  if (colorStyle === 'yellow') {
    //splat.blendMode = PIXI.BLEND_MODES.SCREEN;
  }
  if (colorStyle === 'green') {
    //splat.blendMode = PIXI.BLEND_MODES.SCREEN;
  }
  if (colorStyle === 'black') {
    //splat.blendMode = PIXI.BLEND_MODES.SCREEN;
  }
  return splat;
};

export default Splat;
