import React from 'react';
import PigmentText from './PigmentText';

const PigmentsTexts = ({ section, texts, percent }) => {
  const color = section > 0 ? 'white' : 'black';

  const list = texts.map((e, i) => (
    <PigmentText
      key={i}
      t={e}
      index={i}
      isVisible={
        (i === section && i !== 0) ||
        (section === 0 && i === 0 && percent === 0)
      }
      color={color}
    />
  ));

  // const num = `${section + 1}/${txtData.length}`;

  return (
    <div className='container'>
      <div className='text'>{list}</div>
      <style jsx>{`
        .container {
          pointer-events: none;
          transition: color 0.5s ease-in-out;
        }
        .text {
          position: absolute;
          top: 0;
          left: 50%;
          width: 70%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          text-align: center;
          font-size: 30px;
          -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
          -khtml-user-select: none; /* Konqueror HTML */
          -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none;
        }

        .num {
          position: absolute;
          bottom: 20px;
          right: 20px;
        }
      `}</style>
    </div>
  );
};

export default PigmentsTexts;
