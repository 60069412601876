import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import css from "styled-jsx/css";

const springAnim = {
  ease: [0.86, 0.0, 0.07, 1.0],
  duration: 1.5
};

const UIAnimation = ({ button, isEnded }) => {
  const getStyle = css.resolve`
    div {
      position: absolute;
      right: 0;
      bottom: 0;
      will-change: transform;
      width: 500px;
    }
  `;

  const { className, styles } = getStyle;

  return (
    <AnimatePresence>
      {isEnded && (
        <motion.div
          className={className}
          initial="exit"
          animate="enter"
          exit="exit"
          variants={{
            enter: {
              y: 0,
              transition: {
                ...springAnim
              }
            },
            exit: {
              y: 160,
              zIndex: 2,
              transition: {
                ...springAnim
              }
            }
          }}
        >
          {button}
          {styles}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default UIAnimation;
