import React, { Component } from 'react';

export default class BlockScreen extends Component {
  render() {
    const { showBlockScreen } = this.props;

    if (!showBlockScreen) {
      return null;
    }
    return (
      <div className='container'>
        <style jsx>{`
          .div {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
          }
        `}</style>
      </div>
    );
  }
}
