import React, { useEffect, useState, useRef } from 'react';
import ArtworkCartel from './ArtworkCartel';
import ArtworkImage from './ArtworkImage';
import { UIButton } from 'oslo-ui';

const Artwork = ({
  slug,
  theme,
  artworks,
  showCartelButtons,
  hideArtworkButtons,
  transitioning,
  artworkId,
}) => {
  const [activeIndex, setActiveIndex] = useState(artworkId);
  const [hasRendered, setRendered] = useState(false);
  const [hideImage, setImgHidden] = useState(true);
  const [cartelState, setCartelState] = useState(false);
  const [zoomInButton, setZoomInButton] = useState(false);
  const [zoomOutButton, setZoomOutButton] = useState(false);
  const [zoomInVisible, setZoomInVisible] = useState(false);
  const [zoomOutVisible, setZoomOutVisible] = useState(false);
  const imgRef = useRef();

  useEffect(() => {
    if (hasRendered === false) {
      setRendered(true);
    }
  }, [hasRendered]);
  useEffect(() => {
    setTimeout(() => {
      setImgHidden(false);
    }, 1000);
  }, []);

  const toggleCartel = (state) => {
    setCartelState(state);
  };

  const showButtons = () => {
    setZoomInButton(true);
    setTimeout(() => {
      setZoomInVisible(true);
    }, 100);

    setZoomOutButton(true);
    setTimeout(() => {
      setZoomOutVisible(true);
    }, 100);
  };

  const hideButtons = () => {
    setZoomInVisible(false);
    setTimeout(() => {
      setZoomInButton(false);
    }, 1000);

    setTimeout(() => {
      setZoomOutButton(false);
      setTimeout(() => {
        setZoomOutVisible(false);
      }, 1000);
    }, 100);
  };

  const zoomIn = () => {
    imgRef.current.zoomIn();
  };

  const zoomOut = () => {
    imgRef.current.zoomOut();
  };

  const updateActiveIndex = (index) => {
    if (transitioning) return;
    setImgHidden(true);
    setTimeout(() => {
      setActiveIndex(index);
      setImgHidden(false);
    }, 850);
  };

  useEffect(() => {
    return () => {
      showCartelButtons();
    };
  }, [showCartelButtons]);

  return (
    <section>
      <div className={`bg`} />
      <ArtworkCartel
        artworks={artworks}
        artwork={artworks[activeIndex]}
        updateActiveIndex={updateActiveIndex}
        activeIndex={activeIndex}
        setCartelState={toggleCartel}
        hidden={!cartelState}
        color={theme.main_color}
        transition={hideImage}
      />
      <ArtworkImage
        ref={imgRef}
        hideArtworkButtons={hideArtworkButtons}
        artwork={artworks[activeIndex]}
        slug={slug}
        theme={theme}
        toggleCartel={toggleCartel}
        showButtons={showButtons}
        hide={hideImage}
        hideButtons={hideButtons}
        artworkId={artworkId}
      />
      <React.Fragment>
        {zoomInButton && (
          <UIButton
            type='zoom'
            background={theme.main_color}
            color={theme.second_color}
            click={zoomOut}
            customIcon={require('../../assets/zoomOutMuseum.svg')}
            zindex={2}
            hidden={!zoomInVisible}
          />
        )}
        {zoomOutButton && (
          <UIButton
            type='zoom'
            background={theme.main_color}
            color={theme.second_color}
            click={zoomIn}
            blackLayer={true}
            customIcon={require('../../assets/zoomInMuseum.svg')}
            zindex={1}
            hidden={!zoomOutVisible}
          />
        )}
      </React.Fragment>
      <style jsx>
        {`
          section {
            align-items: center;
            //background-size: 101%;
            display: flex;
            flex-direction: row;
            height: 100%;
            justify-content: center;
            left: 0;
            position: absolute;
            top: 0;
            z-index: 1;
            width: 100%;
          }
        `}
      </style>
    </section>
  );
};

export default Artwork;
