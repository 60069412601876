import React from 'react';

export default class VideoTransition extends React.Component {
  constructor(props) {
    super(props);

    this.restarted = false;
    this.reverse = false;

    this.state = {
      visible: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.loadImage();
    }, 200);
  }

  componentDidUpdate = (prevProps) => {
    let { startReverse } = this.props;

    if (prevProps.startReverse !== startReverse && startReverse) {
      this.reverseVideo();
    }
  };

  reverseVideo = () => {
    const { cacheOut } = this.props;
    this.reverse = true;
    let video = this.myVideoOut;
    video.src = cacheOut;

    cancelAnimationFrame(this.requestAnim);
    const playPromise = video.play();
    if (playPromise !== undefined) {
      playPromise
        .then(() => {
          this.requestAnim = requestAnimationFrame(this.tick);
          this.setState({ visible: true });
        })
        .catch(function (error) {});
    }
  };

  tick = () => {
    if (this.container) {
      let canvas = this.myCanvas;
      let ctx = canvas.getContext('2d');
      let v = this.myVideoIn;
      if (this.reverse) {
        v = this.myVideoOut;
      }
      let maskImg = this.myMask;

      if (v.paused || v.ended) return false;

      // Draw the current frame of the video in the offscreen canvas
      this.offctx.drawImage(
        v,
        0,
        0,
        canvas.width / this.dpr,
        canvas.height / this.dpr
      );
      // turns the current offscreen canvas to imageData
      var idata = this.offctx.getImageData(
        0,
        0,
        canvas.width / this.dpr,
        canvas.height / this.dpr
      );
      var data32 = new Uint32Array(idata.data.buffer);
      var i = 0;
      let len = data32.length;
      // turns img data to alpha
      while (i < len) data32[i] = data32[i++] << 8;
      // Clears the offscreen canvas so we draw the alpha channel
      this.offctx.clearRect(
        0,
        0,
        canvas.width / this.dpr,
        canvas.height / this.dpr
      );
      // draw alpha channel
      this.offctx.putImageData(
        idata,
        0,
        0,
        0,
        0,
        canvas.width / this.dpr,
        canvas.height / this.dpr
      );

      // adds background image
      this.offctx.globalCompositeOperation = 'source-in';
      this.offctx.drawImage(
        maskImg,
        0,
        0,
        canvas.width / this.dpr,
        canvas.height / this.dpr
      );
      this.offctx.globalCompositeOperation = 'darken';
      let btmp = this.offscreen.transferToImageBitmap();
      ctx.clearRect(0, 0, canvas.width / this.dpr, canvas.height / this.dpr);
      ctx.drawImage(btmp, 0, 0);
      requestAnimationFrame(this.tick);
    }
  };

  loadImage = () => {
    this.setState({ canvasWidth: 1920, canvasHeight: 1080 });
    this.initCanvas();
  };

  initCanvas = () => {
    if (this.myCanvas) {
      let canvas = this.myCanvas;
      let ctx = canvas.getContext('2d');
      this.dpr = window.devicePixelRatio || 1;
      // Get the size of the canvas in CSS pixels.
      var rect = canvas.getBoundingClientRect();
      this.requestAnim = requestAnimationFrame(this.tick);
      // Give the canvas pixel dimensions of their CSS
      // size * the device pixel ratio.
      canvas.width = rect.width * this.dpr;
      canvas.height = rect.height * this.dpr;
      this.offscreen = new OffscreenCanvas(canvas.width, canvas.height);
      this.offctx = this.offscreen.getContext('2d');

      // Scale all drawing operations by the dpr, so you
      // don't have to worry about the difference.
      ctx.scale(this.dpr, this.dpr);

      let video = this.myVideoIn;

      const playPromise = video.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            this.setState({ visible: true });
          })
          .catch(function (error) {});
      }

      let started = false;
      video.addEventListener('timeupdate', () => {
        if (!started) {
          started = true;
        }
      });
    }
  };

  render() {
    const { visible } = this.state;

    const { cacheIn, cacheOut, background } = this.props;

    return (
      <div
        className={visible ? 'visible' : ''}
        ref={(c) => (this.container = c)}
      >
        <canvas ref={(c) => (this.myCanvas = c)} width='100%' height='100%' />
        <video
          ref={(c) => (this.myVideoIn = c)}
          playsInline
          muted
          loop={false}
          src={cacheIn}
        />
        <video
          ref={(c) => (this.myVideoOut = c)}
          playsInline
          muted
          loop={false}
          src={cacheOut}
        />
        <img src={background} ref={(e) => (this.myMask = e)} alt='' />
        <style jsx>{`
          div {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            pointer-events: none;
          }
          canvas {
            height: 100%;
            width: 100%;
          }
          img,
          video {
            display: none;
          }
        `}</style>
      </div>
    );
  }
}
