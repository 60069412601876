const getMousePos = (e, auto, isFlipped) => {
  if (auto) {
    const x =
      e.nativeEvent && e.nativeEvent.offsetX
        ? e.nativeEvent.offsetX
        : e.clientX
        ? e.clientX
        : e.touches && e.touches[0].clientX;
    const y =
      e.nativeEvent && e.nativeEvent.offsetY
        ? e.nativeEvent.offsetY
        : e.clientY
        ? e.clientY
        : e.touches && e.touches[0].clientY;

    return {
      x,
      y,
    };
  }

  let pos = { x: e.clientX, y: e.clientY };
  if (e.touches) {
    pos = { x: e.touches[0].clientX, y: e.touches[0].clientY };
  }
  const rect = e.target ? e.target.getBoundingClientRect() : null;
  if (!rect) {
    return {
      x: 0,
      y: 0,
    };
  }
  const x_rel = pos.x - rect.left;
  const y_rel = pos.y - rect.top;
  let x = Math.round((x_rel * e.target.width) / rect.width);
  let y = Math.round((y_rel * e.target.height) / rect.height);

  if (isFlipped) {
    x = e.target.width - x;
    y = e.target.height - y;
  }
  return { x, y };
};

const getRandomFloat = (min, max) => {
  return Math.random() * (max - min) + min;
};

const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const getRandomBool = () => {
  return Math.random() >= 0.5;
};

export { getMousePos, getRandomFloat, getRandomInt, getRandomBool };
